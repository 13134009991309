@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.ErrorPage {
	display: flex;
	padding-top: 120px;
	flex-direction: column;
	height: 100vh;
	//dont use shorthand for background in IE...
	background-image: url('images/background.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;

	.ErrorPage__link {
		color: $light;
		text-decoration: underline;

		&:focus,
		&:active,
		&:hover {
			text-decoration: underline;
		}
	}

	.ErrorPage__text {
		color: $passiveLighter;
	}
}
