/*Regular font*/
@font-face {
	font-family: font-reg;
	src: url('fonts/DinWebPro/DinWebPro.woff2') format('woff2'), url('fonts/DinWebPro/DinWebPro.woff') format('woff'),
		url('fonts/DinWebPro/DinWebPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/*Bold font*/
@font-face {
	font-family: font-bold;
	src: url('fonts/DinWebPro-Bold/DinWebPro-Bold.woff2') format('woff2'),
		url('fonts/DinWebPro-Bold/DinWebPro-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/*Light font*/
@font-face {
	font-family: font-light;
	src: url('fonts/DinWebPro-Light/DinWebPro-Light.woff2') format('woff2'),
		url('fonts/DinWebPro-Light/DinWebPro-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
