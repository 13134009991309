// AppSwitcher is visible on all applications in top corner of screen
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.AppSwitcher__button.open {
	background-color: $passiveBlack;
}

.AppSwitcher__selector {
	height: 60px !important;
	width: 68px !important;
	display: flex;
	cursor: pointer;
}

.AppSwitcher__selectorMenu {
	position: absolute;
	background-color: $passiveBlack;
	width: 547px;
	right: 0;
	top: 60px;

	@media (max-width: $tabletPortrait - 1) {
		width: 100%;
	}

	@media (max-width: $mobile) {
		height: auto;
	}
}

.AppSwitcher__srollableHolder {
	overflow: auto;
	max-height: 430px;
}
