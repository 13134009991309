// TODO: check if we use this anywhere ??? (qqqwwwcssbem)
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.TabMenu {
	background-color: $light;
	border-bottom: 1px solid $borderPanel;

	.TabMenu__link {
		float: left;
		height: 60px;
		margin: 0 10px;
		padding: 0 10px;
		line-height: 60px;
		color: $passiveDark;

		&:first-of-type {
			margin: 0 10px 0 0;
		}

		&--active {
			border-bottom: 3px solid $primary;
			font-family: font-bold, sans-serif;
		}

	}

	.TabMenu__appName {
		color: $primary;
		height: 60px;
		line-height: 60px;
		font-size: 20px;
		margin: 0;
	}

}