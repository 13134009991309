// Left sidebar visible on any app that have multiple pages, or when browser window is shrieked
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.Sidebar {
	position: fixed;
	height: 100%;
	z-index: 1000;
	background-color: $passiveBlack;
	width: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	top: 60px;
	padding-bottom: 60px;

	@media (max-width: $mobile) {
		width: 100%;
	}

	.Sidebar__item {
		box-shadow: 0 1px 0 0 $passiveDark;
		transition: box-shadow 0.1s linear;

		&:hover,
		&--active {
			box-shadow: 0 1px 0 0 $primary, 0 -1px 0 0 $primary;
			background-color: $passiveMedium;

			.Sidebar__link {
				color: $primary;
			}
		}

		&--active {
			background-color: $passiveDark;
		}
	}

	.Sidebar__link {
		color: $light;
		height: 58px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
	}

	.Sidebar__icon {
		display: flex;
		margin-right: 20px;
	}

	.Sidebar__name {
		float: left;
		height: 58px;
		line-height: 58px;
		margin: 0;
		font-size: 20px;
		color: inherit;
	}
}
