@import "~gogo-sphere-shared-components/assets/sass/base/variables";

.PasswordStrength {
  text-align: left;
  max-width: 80%;
  margin: 10px auto 0;
}

.PasswordStrength__message {
  padding-left: 22px;

  &--valid {
    background: url('~gogo-sphere-shared-components/assets/images/svg/check-mark-sign-green-ico.svg') no-repeat 0 2px;
    background-size: 18px;
  }

  &--error {
    background: url('~gogo-sphere-shared-components/assets/images/svg/close-ico-red.svg') no-repeat 0 2px;
    background-size: 18px;
  }
}

.PasswordStrength__errorIcon {
  font-size: 18px;
  color: $error;
}

.PasswordStrength__heading {
  display: inline-block;
}