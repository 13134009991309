

html, #app {
    height: 100%;
}

body {
    margin: 0;
}

.centerVerticallyAndHorizontally {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backgroundGreyGradient {
    background: #c1c1c1; /* Old browsers */
    /* IE10+ */
    background-image: -ms-radial-gradient(center, ellipse farthest-corner, #E3E3E3 0%, #C8C6CC 100%);

    /* Mozilla Firefox */
    background-image: -moz-radial-gradient(center, ellipse farthest-corner, #E3E3E3 0%, #C8C6CC 100%);

    /* Opera */
    background-image: -o-radial-gradient(center, ellipse farthest-corner, #E3E3E3 0%, #C8C6CC 100%);

    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(radial, center center, 0, center center, 286, color-stop(0, #E3E3E3), color-stop(100, #C8C6CC));

    /* Webkit (Chrome 11+) */
    background-image: -webkit-radial-gradient(center, ellipse farthest-corner, #E3E3E3 0%, #C8C6CC 100%);

    /* W3C Markup */
    background-image: radial-gradient(ellipse farthest-corner at center, #E3E3E3 0%, #C8C6CC 100%);
}

.spinner {
    width: 177px;
    text-align: center;
}

.spinner > div {
    width: 33px;
    height: 33px;
    margin: 10px;
    background-color: #a6a6a6;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}