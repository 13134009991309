.LoginBox {
  margin: 3% auto 0 auto;
  width: 460px;
  color: #fff;
}

@media (max-width: 1024px) {
  .LoginBox {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 0;
    width: 320px;
  }
}

.LoginBox__comment {
  font-family: font-light, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #ededed;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .LoginBox__comment {
    text-align: center;
  }
}

.LoginBox__heading {
  display: inline-block;
  font-family: font-bold, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: inherit;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .LoginBox__heading {
    width: 100%;
    text-align: center;
  }
}

.LoginBox__linkHolder {
  font-size: 18px;
  color: #fff;
  text-align: right;
}

.LoginBox__link {
  color: inherit;
  text-decoration: underline;
}

.LoginBox__link:hover {
  text-decoration: underline;
}

.LoginBox__link--left {
  text-align: left;
}

.LoginBox__form {
  margin-top: 50px;
}

.LoginBox__formElement {
  padding-right: 0;
  margin-bottom: 24px;
}

.LoginBox__formElement:disabled {
  background-color: #e1e1e1;
}

.LoginBox__error {
  border: 2px solid #e80029;
  border-radius: 6px;
  background-color: #fff;
  color: #595959;
  font-size: 18px;
  padding: 35px;
  position: relative;
}

.LoginBox__error--success {
  border-color: #169616;
}

.LoginBox__errorSlogan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginBox__message {
  font-size: 14px;
}

.NotificationError {
  border: 2px solid #e80029;
  border-radius: 6px;
  background-color: #fff;
  color: #595959;
  font-size: 18px;
  display: inline-block;
  height: 50px;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0 10px;
}

.NotificationError .NotificationError__text {
  line-height: 50px;
  height: 50px;
  display: inline-block;
  margin-left: 10px;
}

.NotificationError .NotificationError__icon {
  color: #e80029;
  font-size: 33.44px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.AppFooter {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.depoapis .AppFooter {
  right: 0;
  bottom: 5px;
  width: 100%;
}

@media (max-width: 1023px) {
  .depoapis .AppFooter {
    width: calc(100% - 180px);
  }
}

@media (max-width: 700px) {
  .depoapis .AppFooter {
    width: 100%;
  }
}

.Header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #3d3d3d;
  color: #fff;
  z-index: 1000;
}

.Header__logos {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  height: 60px;
  display: flex;
  align-items: center;
}

.Header__logos--pullLeft {
  position: static;
  float: left;
  transform: translate(30px);
}

@media (max-width: 1023px) {
  .Header__logos {
    position: static;
    float: left;
    transform: translate(25px);
  }
}

@media (max-width: 480px) {
  .Header__logos {
    transform: translate(5px);
  }
}

.Header__ggsLogo {
  height: 47px;
  width: 142px;
  background-image: url("./images/logo-white.svg");
  background-size: cover;
}

@media (max-width: 480px) {
  .Header__ggsLogo {
    width: 40px;
  }
}

.Header__lineSeparator {
  width: 1px;
  height: 35px;
  background-color: #efefef;
  margin: 12px;
}

@media (max-width: 767px) {
  .Header__lineSeparator {
    display: none;
  }
}

@media (max-width: 767px) {
  .Header__airlineLogo {
    display: none;
  }
}

.SidebarExpandBtn {
  background-color: #3d3d3d;
  width: 60px;
  height: 60px;
  color: white;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}

.SidebarExpandBtn.open {
  background-color: #1f1f1f;
}

.ProfileMenu {
  width: 400px;
  position: absolute;
  right: 0;
  top: 60px;
  background-color: #1f1f1f;
}

@media (max-width: 480px) {
  .ProfileMenu {
    width: 100%;
  }
}

.ProfileMenu .ProfileMenu__head {
  padding: 20px 20px 30px 20px;
}

.ProfileMenu .ProfileMenu__airline {
  text-align: center;
}

.ProfileMenu .ProfileMenu__airlineTitle {
  color: #fff;
}

.ProfileMenu .ProfileMenu__bottomPart {
  padding: 0;
}

.UserProfile {
  width: 230px;
  background-color: #3d3d3d;
  font-size: 16px;
  line-height: 60px;
}

.UserProfile.open {
  background-color: #1f1f1f;
}

@media (max-width: 768px) {
  .UserProfile {
    min-width: initial !important;
    width: initial !important;
  }
}

.UserProfile .UserProfile__link {
  color: #fff;
  overflow: hidden;
  height: 60px;
  padding: 0 25px;
  text-overflow: ellipsis;
}

.UserProfile .UserProfile__link:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .UserProfile .UserProfile__link span {
    display: none;
  }

  .UserProfile .UserProfile__link .UserProfile__mobileIcon {
    display: inline;
  }
}

.UserProfile .UserProfile__mobileIcon {
  display: none;
  font-size: 27px;
  position: relative;
  top: 3px;
}

main#cmp .UserProfile {
  min-width: 222px;
  background-color: #2e2e2e;
}

main#cmp .UserProfile.open {
  background-color: #1f1f1f;
}

.Login {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.Login .Login__logo {
  display: inline-block;
  width: 190px;
  min-height: 63px;
  margin: 40px 0;
  background-image: url("./images/logo-white.svg");
  background-size: cover;
}

.ErrorPage {
  display: flex;
  padding-top: 120px;
  flex-direction: column;
  height: 100vh;
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.ErrorPage .ErrorPage__link {
  color: #fff;
  text-decoration: underline;
}

.ErrorPage .ErrorPage__link:focus,
.ErrorPage .ErrorPage__link:active,
.ErrorPage .ErrorPage__link:hover {
  text-decoration: underline;
}

.ErrorPage .ErrorPage__text {
  color: #efefef;
}

.NotificationError {
  border: 2px solid #e80029;
  border-radius: 6px;
  background-color: #fff;
  color: #595959;
  font-size: 18px;
  display: inline-block;
  height: 50px;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0 10px;
}

.NotificationError .NotificationError__text {
  line-height: 50px;
  height: 50px;
  display: inline-block;
  margin-left: 10px;
}

.NotificationError .NotificationError__icon {
  color: #e80029;
  font-size: 33.44px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.Sidebar {
  position: fixed;
  height: 100%;
  z-index: 1000;
  background-color: #1f1f1f;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 480px) {
  .Sidebar {
    width: 100%;
  }
}

.Sidebar .Sidebar__item {
  box-shadow: 0 1px 0 0 #3d3d3d;
  transition: box-shadow 0.1s linear;
}

.Sidebar .Sidebar__item:hover,
.Sidebar .Sidebar__item--active {
  box-shadow: 0 1px 0 0 #40B1E5, 0 -1px 0 0 #40B1E5;
  background-color: #595959;
}

.Sidebar .Sidebar__item:hover .Sidebar__link,
.Sidebar .Sidebar__item--active .Sidebar__link {
  color: #40B1E5;
}

.Sidebar .Sidebar__item--active {
  background-color: #3d3d3d;
}

.Sidebar .Sidebar__link {
  color: #fff;
  height: 58px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.Sidebar .Sidebar__icon {
  display: flex;
  margin-right: 20px;
}

.Sidebar .Sidebar__name {
  float: left;
  height: 58px;
  line-height: 58px;
  margin: 0;
  font-size: 20px;
  color: inherit;
}

.SSOLogin__separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SSOLogin__separatorOr {
  width: 10%;
  background-color: transparent;
  color: #d7d7d7;
  font-size: 15px;
}

.SSOLogin__separatorLineLeft {
  width: 40%;
  height: 1px;
  background-color: #d7d7d7;
}

.SSOLogin__separatorLineRight {
  width: 40%;
  height: 1px;
  background-color: #d7d7d7;
}

.SSOLogin__button {
  text-align: center;
  border: solid 2px #2084ba !important;
  border-radius: 4px;
  color: #40B1E5;
}

.SSOLogin__button:disabled {
  color: #40B1E5 !important;
}

.SSOLanding {
  color: #fff;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  background-image: url("./images/background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.SSOLanding .ErrorPage__link {
  color: #fff;
  text-decoration: underline;
}

.SSOLanding .ErrorPage__link:focus,
.SSOLanding .ErrorPage__link:active,
.SSOLanding .ErrorPage__link:hover {
  text-decoration: underline;
}

.AppSwitcher__button.open {
  background-color: #1f1f1f;
}

.AppSwitcher__selector {
  height: 60px !important;
  width: 68px !important;
  display: flex;
  cursor: pointer;
}

.AppSwitcher__selectorMenu {
  position: absolute;
  background-color: #1f1f1f;
  width: 547px;
  right: 0;
  top: 60px;
}

@media (max-width: 767px) {
  .AppSwitcher__selectorMenu {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .AppSwitcher__selectorMenu {
    height: auto;
  }
}

.AppSwitcher__srollableHolder {
  overflow: auto;
  max-height: 430px;
}

.TabMenu {
  background-color: #fff;
  border-bottom: 1px solid #d7d7d7;
}

.TabMenu .TabMenu__link {
  float: left;
  height: 60px;
  margin: 0 10px;
  padding: 0 10px;
  line-height: 60px;
  color: #3d3d3d;
}

.TabMenu .TabMenu__link:first-of-type {
  margin: 0 10px 0 0;
}

.TabMenu .TabMenu__link--active {
  border-bottom: 3px solid #40B1E5;
  font-family: font-bold, sans-serif;
}

.TabMenu .TabMenu__appName {
  color: #40B1E5;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  margin: 0;
}

.PasswordStrength {
  text-align: left;
  max-width: 80%;
  margin: 10px auto 0;
}

.PasswordStrength__message {
  padding-left: 22px;
}

.PasswordStrength__message--valid {
  background: url("~gogo-sphere-shared-components/assets/images/svg/check-mark-sign-green-ico.svg") no-repeat 0 2px;
  background-size: 18px;
}

.PasswordStrength__message--error {
  background: url("~gogo-sphere-shared-components/assets/images/svg/close-ico-red.svg") no-repeat 0 2px;
  background-size: 18px;
}

.PasswordStrength__errorIcon {
  font-size: 18px;
  color: #e80029;
}

.PasswordStrength__heading {
  display: inline-block;
}

