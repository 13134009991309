// Header hold AppSwitcher, ProfileMenu, SidebarExpandBtn ...
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.Header {
	position: fixed;
	width: 100%;
	height: 60px;
	background-color: $passiveDark;
	color: $light;
	z-index: 1000;
}

.Header__logos {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	height: 60px;
	display: flex;
	align-items: center;

	&--pullLeft {
		position: static;
		float: left;
		transform: translate(30px);
	}

	@media (max-width: $tabletLandscape - 1) {
		position: static;
		float: left;
		transform: translate(25px);
	}

	@media (max-width: $mobile) {
		transform: translate(5px);
	}
}

//main#cmp {
//	.Header__logos {
//		position: static;
//		float: left;
//		transform: translate(30px);
//	}
//}

.Header__ggsLogo {
	height: 47px;
	width: 142px;
	background-image: url('images/logo-white.svg');
	background-size: cover;

	@media (max-width: $mobile) {
		width: 40px;
	}
}

.Header__lineSeparator {
	width: 1px;
	height: 35px;
	background-color: $passiveLighter;
	margin: 12px;

	@media (max-width: $tabletPortrait - 1) {
		display: none;
	}
}

.Header__airlineLogo {
	@media (max-width: $tabletPortrait - 1) {
		display: none;
	}
}

.Header__noAirlineLogo {
	//line-height: 60px;
}

.SidebarExpandBtn {
	background-color: $passiveDark;
	width: 60px;
	height: 60px;
	color: white;
	padding: 16px 20px;
	display: flex;
	align-items: center;

	&.open {
		background-color: $passiveBlack;
	}
}
