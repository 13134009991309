@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.SSOLogin__separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SSOLogin__separatorOr {
  width: 10%;
  background-color: transparent;
  color: $passiveSoft;
  font-size: 15px;
}

.SSOLogin__separatorLineLeft {
  width: 40%;
  height: 1px;
  background-color: $passiveSoft;
}

.SSOLogin__separatorLineRight {
  width: 40%;
  height: 1px;
  background-color: $passiveSoft;
}

.SSOLogin__button {
  text-align: center;
  border: solid 2px #2084ba !important;
  border-radius: 4px;
  color: $primary;

  &:disabled {
    color: $primary !important;
  }
}

.SSOLanding {
  color: $light;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  //dont use shorthand for background in IE...
  background-image: url('images/background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;

  .ErrorPage__link {
		color: $light;
		text-decoration: underline;

		&:focus,
		&:active,
		&:hover {
			text-decoration: underline;
		}
	}
}