// Footer is visible on all pages at the bottom of the page
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.AppFooter {
	position: absolute;
	bottom: 0;
	text-align: center;
	width: 100%;
}

//on depo app (depoapis page) we have left sidebar, so we need to align footer to right side of page
.depoapis .AppFooter {
	right: 0;
	bottom: 5px;
	width: 100%;

	@media (max-width: 1023px) {
		width: calc(100% - 180px);
	}

	@media (max-width: 700px) {
		width: 100%;
	}
}
