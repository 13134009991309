// NotificationError TODO: check and merge all Notifications in one scss file (qqqwwwcssbem)
//----------------------------------------------------------------------------------------------------------------------
//
@import "~gogo-sphere-shared-components/assets/sass/base/variables";

.NotificationError {
	border: 2px solid $alternative;
	border-radius: 6px;
	background-color: $light;
	color: $passiveMedium;
	font-size: 18px;
	display: inline-block;
	height: 50px;
	margin-top: 5px;
	margin-left: 10px;
	padding: 0 10px;

	.NotificationError__text {
		line-height: 50px;
		height: 50px;
		display: inline-block;
		margin-left: 10px;
	}

	.NotificationError__icon {
		color: $alternative;
		font-size: 33.44px;
		display: inline-block;
		position: relative;
		top: 5px;
	}
}