@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.Login {
	height: 100%;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	flex-direction: column;
	overflow: auto;
	//dont use shorthand for background in IE...
	background-image: url('images/background.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;

	.Login__logo {
		display: inline-block;
		width: 190px;
		min-height: 63px;
		margin: 40px 0;
		background-image: url('images/logo-white.svg');
		background-size: cover;
	}
}
