// LoginBox is visible on login page
//----------------------------------------------------------------------------------------------------------------------
//
@import '~gogo-sphere-shared-components/assets/sass/base/variables';

.LoginBox {
	margin: 3% auto 0 auto;
	width: 460px;
	color: $light;

	@media (max-width: $tabletLandscape) {
		padding-left: 30px;
		padding-right: 30px;
		margin-top: 0;
		width: 320px;
	}
}

.LoginBox__comment {
	font-family: font-light, sans-serif;
	font-size: 18px;
	line-height: 22px;
	color: darken($light, 7%);
	margin-top: 2px;

	@media (max-width: $tabletPortrait) {
		text-align: center;
	}
}

.LoginBox__heading {
	display: inline-block;
	font-family: font-bold, sans-serif;
	font-size: 24px;
	line-height: 24px;
	color: inherit;
	margin-bottom: 10px;

	@media (max-width: $tabletPortrait) {
		width: 100%;
		text-align: center;
	}
}

.LoginBox__linkHolder {
	font-size: 18px;
	color: $light;
	text-align: right;
}

.LoginBox__link {
	color: inherit;
	text-decoration: underline;

	&:hover{
		text-decoration: underline;
	}
}

.LoginBox__link--left {
	text-align: left;
}

.LoginBox__form {
	margin-top: 50px;
}

.LoginBox__formElement {
	padding-right: 0;
	margin-bottom: 24px;

	&:disabled {
		background-color: $passiveLight;
	}
}

.LoginBox__error {
	border: 2px solid $error;
	border-radius: 6px;
	background-color: $light;
	color: $passiveMedium;
	font-size: 18px;
	padding: 35px;
	position: relative;
}

.LoginBox__error--success {
	border-color: $success;
}

.LoginBox__errorSlogan {
	display: flex;
	justify-content: center;
	align-items: center;
}

.LoginBox__message {
	font-size: 14px;
}
